<template>
  <div class="container">
    <!-- test -->
    <!-- <el-backtop> </el-backtop> -->
    <div class="right"
         style="width: 100%">
      <!-- 公司业务 -->
      <div class="produce_title">
        <div class="back"
             @click="back">
          <el-button type="primary"
                     size="mini">返回</el-button>
        </div>
        <div style=""
             class="pName">
          {{ data.pname }}
          <!-- <div class="border"></div> -->
        </div>

        <div class="pcreateTime">
          {{ data.pcreateTime }}
        </div>
      </div>

      <div class="produce">
        <div class="produce_List"
             style="color: #000; font-weight: 550">
          项目背景
        </div>
        <div class="product_content">
          <!-- <div v-for=" item in detailList">
            <div>{{detailList.pdName}}</div>
          </div> -->
        </div>
        <!-- <div class="product_content">
          <div><span>定义内涵：</span>{{ data.pdefinition }}</div>
          <div><span>问题痛点：</span> {{ data.pproblem }}</div>
          <div><span>解决路径：</span>{{ data.psolution }}</div>
          <div><span>预期成效：</span>{{ data.pcurrent }}</div>
        </div> -->
        <!-- <div class="produce_List" style="margin-bottom: 10px">项目架构</div>
        <div
          class="html"
          style="text-align: left"
          v-if="data.pprojectFramework"
        >
          <div v-html="data.pprojectFramework"></div>
        </div>

        <div class="produce_List">系统简介</div>
        <div style="text-align: left" v-if="data.psystemIntro" class="html">
          <div v-html="data.psystemIntro"></div>
        </div>
        <div class="produce_List">功能介绍</div>

        <div class="html" style="text-align: left" v-if="data.pintro">
          <div v-html="data.pintro" class="textStyle ql-editor"></div>
        </div> -->
        <div v-for="(item, index) in detailList"
             :key="index">
          <div class="produce_List"
               v-text="item.pdName"></div>
          <div class="product_content"
               style="text-align: left; font-size: 16px; text-indent: 2em"
               v-if="item.pdType == 1">
            <div v-text="item.pdContent"></div>
          </div>
          <div class="html"
               style="
              text-align: left;
              text-align: left;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              align-items: center;
            "
               v-if="item.pdType == 2">
            <img style="width: 60%; padding-bottom: 10px"
                 v-for="(img, indexs) in item.pdContent"
                 :key="indexs"
                 :src="img" />
          </div>
          <div class="html"
               style="text-align: left"
               v-if="item.pdType == 3">
            <div v-html="item.pdContent"
                 class="textStyle ql-editor"></div>
          </div>
        </div>
        <div class="detail_bottom">
          <div class="detail_bottom_left"
               v-if="data.ptype == 1 && data.ppictureUrl">
            <!-- <img
              :src="data.paddress"
              alt=""
              style="width: 150px; height: 150px"
            /> -->
            <el-image :src="data.ppictureUrl"
                      alt=""
                      style="width: 150px; height: 150px"
                      :preview-src-list="[data.ppictureUrl]"></el-image>
            <div style="font-size: 20px">微信扫一扫</div>
          </div>
          <div :class="
              data.ptype == 2 ? 'detail_bottom_right2' : 'detail_bottom_right'
            ">
            <div style="text-align: center; padding-bottom: 20px"
                 @click="openLink"
                 v-if="data.paddress !== '' && data.paddress !== null">
              驾驶舱链接：
              <span :style="
                  data.paddress
                    ? 'color: #409EFF;border-bottom: 1px solid #409EFF'
                    : 'color:#000'
                ">{{ data.paddress ? "点击查看" : "暂无" }}</span>
              <!-- <body style='overflow:-Scroll;overflow-y:hidden'>
                  <iframe allowfullscreen=“true” width='100%' height='100%'
                :style="
                  data.paddress
                    ? 'color: #409EFF;border-bottom: 1px solid #409EFF'
                    : 'color:#000'"
                :src=" data.paddress ? data.paddress : '' "></iframe>
                </body> -->
            </div>
            <div class="report"
                 v-if="options[0]">
              <div class="reportTitle">新闻报道：</div>
              <div v-if="options[0]"
                   class="ntitleList">
                <div v-show="!more">
                  <div v-for="(item, index) in options.slice(0, 1)"
                       :key="index"
                       @click="ToNewDetails(item.nid)"
                       class="ntitle">
                    {{ item.ntitle }}
                  </div>
                </div>
                <div v-show="more">
                  <div v-for="(item, index2) in options"
                       :key="index2"
                       @click="ToNewDetails(item.nid)"
                       class="ntitle">
                    {{ item.ntitle }}
                  </div>
                </div>
                <!-- <el-select
                  @change="change"
                  clearable
                  v-model="value"
                  placeholder="请选择"
                  style="border: none; background: none; width: 260px"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.nid"
                    :label="item.ntitle"
                    :value="item.nid"
                  >
                  </el-option>
                </el-select> -->
              </div>
              <div v-if="options.length > 1"
                   class="more"
                   @click="changeMore">
                {{ moreText }}
              </div>

              <div v-if="!options[0]">暂无</div>
            </div>

            <div style="display: flex; align-items: center"
                 v-if="this.data.pfileImg !== '' && this.data.pfileImg !== null">
              <!-- v-if="data.pfileUrl" -->
              项目方案：
              <div class="chakan"
                   @click="chakan">
                <!-- <a :href="pfileUrl" style="color: #fff">  -->
                <el-button type="primary"
                           size="mini">点击查看</el-button>

                <!-- </a> -->
              </div>
              <!-- <el-button type="primary" size="mini" @click="test">测试</el-button> -->
            </div>
          </div>
        </div>
        <div class="back"
             @click="back"
             v-if="nav_show">
          <el-button type="primary"
                     size="mini"
                     style="position: fixed; bottom: 2%; right: 3%">返回</el-button>
        </div>
        <!-- <div class="produce_List" style="margin-bottom: 10px">主要功能</div>
        <div class="product_intro">
          <div class="product_intro_img">
            <img :src="data.plogo" alt="" />
          </div>
          <div class="product_intro_c">
            <span v-html="data.pintro"></span>
          </div>
        </div>
        <div class="product_intro_b">
          <div style="text-align: center">
            <img :src="data.ppictureUrl" alt="" />
          </div>
          <div style="font-size: 16px; text-align: center">
            {{ data.paddress }}
          </div>
        </div> -->

        <!-- <el-dialog :visible.sync='imgVisbe' width="50%">
          <iframe :src="imgVisbleURL"></iframe>
        </el-dialog> -->
      </div>
    </div>
    <el-dialog width="80%"
               :visible.sync="dialogVisible"
               :fullscreen="true"
               :before-close="handleClose">
      <!-- <pdf
        v-for="i in numPages"
        style="width: 100%"
        :key="i"
        :src="pdfSrc"
        :page="i"
        @page-loaded="pageLoaded"
      >
      </pdf> -->
      <img style="width: auto; height: auto; max-width: 100%; max-height: 100%"
           v-for="(item, index) in loadingUrl"
           :key="index"
           :src="item"
           alt="图片" />
      <!-- <div v-if="loadingFlag">加载中....</div> -->
      <!-- <pdf
      ref="pdf"
      :src="pfileUrl" 
      :page="page"
      @num-pages="pageCounts"
      ></pdf>
       <ul class="footers">
      <li
        @click="changePdfPage(0)"
      >
        <p class="up-p">上一页</p>
      </li>
      <li
        @click="changePdfPage(1)"
      >
        <p class="down-p">下一页</p>
      </li>
      <li>
        <p>当前第{{ page }}页/共{{ pageCount }}页</p>
      </li>

       </ul> -->
      <span slot="footer"
            class="dialog-footer"> </span>
    </el-dialog>
    <el-dialog class="closebtn"
               :visible.sync="dialogVisible1"
               width="100%"
               :before-close="handleClose1">
      <iframe id="bi_iframe"
              allowfullscreen="“true”"
              height="100%"
              width="100%"
              scrolling="auto"
              frameborder="0"
              :style="
          data.paddress
            ? 'color: #409EFF;border-bottom: 1px solid #409EFF;'
            : 'color:#000'
        "
              :src="data.paddress ? data.paddress : ''">
        <button type="primary">返回</button>
      </iframe>
    </el-dialog>
  </div>
</template>

<script>
import { getProductDetail } from "../../utils/api.js";
import pdf from "vue-pdf";
export default {
  // components: {
  //   pdf,
  // },
  data () {
    return {
      windowWidth: "",
      potoUrl: "",
      value: "",
      id: "",
      imgaes: [],
      data: {},
      detailList: [],
      fullscreen: false,
      dialogVisible: false,
      dialogVisible1: false,
      options: [],
      pfileUrl: "",
      showBtn: false,
      imgVisbe: false,

      more: false,
      moreText: "更多",
      loadingUrl: [],

      // page:1,
      // pageCount:0
      pdfSrc: "",
      // pdf加载动画
      loadingFlag: true,
      // pdf分页
      numPages: undefined,
      fullscreenLoading: false,
      pageLoadNum: 1,
      nav_show: false,
    };
  },

  created () {
    console.log(1111);
    // console.log(123);
    // console.log(this.data);
    // console.log(this.detailList);
    // console.log(this.$route.query.id);
    this.windowWidth = document.documentElement.clientWidth * 0.39;
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getProductDetail();
    }
  },
  // mounted () {
  //   window.onresize = () => {
  //     this.adjustIframe()
  //     console.log(1112233);
  //   }
  // },
  methods: {
    // test(){
    // window.open('https://dandaoipad.oss-cn-hangzhou.aliyuncs.com/dandaoipad/1891669744608.pdf')
    // },
    // adjustIframe() {
    //   var ifm = document.getElementById("bi_iframe");
    //   ifm.height = document.documentElement.clientHeight;
    //   console.log('----------');
    // },
    changeMore () {
      this.more = !this.more;
      if (this.moreText == "更多") {
        this.moreText = "隐藏";
      } else {
        this.moreText = "更多";
      }
    },

    //获取数据npm i vue-pdf@4.2.0
    handleGetData () {
      console.log("handleGetData");
      //得到pdf地址后执行
      this.pdfSrc = pdf.createLoadingTask(this.pdfSrc);
      this.pdfSrc.promise.then((pdf) => {
        this.numPages = pdf.numPages;
        console.log(this.numPages);
      });
    },
    pageLoaded () {
      const loading = this.$loading({
        lock: true,

        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      this.pageLoadNum++;
      if (this.pageLoadNum == this.numPages) {
        setTimeout(() => {
          loading.close();
        }, 1000);
        console.log(this.pageLoadNum, this.numPages, "pageLoaded");
      }
    },

    // pageCounts(e){
    //       this.pageCount=e
    // },
    // changePdfPage(val) {
    //   if (val === 0 && this.page > 1) {
    //     this.page--;
    //   }
    //   if (val === 1 && this.page < this.pageCount) {
    //     this.page++;
    //   }
    //   console.log(this.page);
    //   console.log(this.pageCount);

    // },
    handleFullScreen () {
      if (new Date().getTime() - this.clickTtime < 500) {
        // console.log("双击");
        this.fullscreen = !this.fullscreen;
      } else {
        this.clickTtime = new Date().getTime();
        // console.log("单机")
      }
    },
    handleClose1 () {
      this.dialogVisible1 = false;
    },
    handleClose () {
      this.dialogVisible = false;
    },
    getProductDetail () {
      getProductDetail({ pId: this.id }).then((res) => {
        if (res.code == 20000) {
          console.log(res);
          this.data = res.data;
          this.detailList = res.data.productDetailsList;
          console.log(this.data.pfileImg);
          console.log(this.detailList);
          this.detailList.forEach((item) => {
            if (item.pdType == 2) {
              item.pdContent = item.pdContent.split(",");
              console.log(this.detailList);
            }
          });
          this.imgaes = this.data.pfileImg.split(",");
          if (res.data.newsList != []) {
            this.options = res.data.newsList;
          }
          if (res.data.pfileUrl) {
            this.pdfSrc = res.data.pfileUrl;
            this.handleGetData();
          }
        }
      });
    },
    openLink () {
      // if (this.data.paddress) {
      //   window.open(this.data.paddress);
      //   // window.location.href = this.data.paddress
      // }
      this.dialogVisible1 = true;
    },
    ToNewDetails (id) {
      console.log(id);
      this.$router.push({ path: "/newDetails", query: { id: id } });
    },
    // change() {
    //   console.log(this.value);
    //   this.$router.push({ path: "/newDetails", query: { id: this.value } });
    // },

    chakan () {
      // console.log(this.imgaes);
      console.log(this.loadingUrl);
      if (this.loadingUrl.length == 0) {
        for (var i = 0; i < this.imgaes.length; i++) {
          this.imgaes[i] =
            "https://dandaoipad.oss-cn-hangzhou.aliyuncs.com/dandaoipad/" +
            this.imgaes[i];
        }
        this.loadingUrl = this.imgaes;
      } else {
        console.log("didiid");
      }
      console.log(this.loadingUrl);
      // this.loadingUrl = this.imgaes[i]
      // var type = this.data.pfileUrl.substring(
      //   this.data.pfileUrl.length - 4,
      //   this.data.pfileUrl.length
      // );
      // console.log(type);
      // if (type == ".pdf") {
      //   this.pfileUrl = this.data.pfileUrl;
      // } else {
      //   this.pfileUrl =
      //     // "https://view.officeapps.live.com/op/view.aspx?src=" +
      //     this.data.pfileUrl;
      // }
      // console.log(this.pfileUrl);
      this.dialogVisible = true;
    },
    back () {
      this.$router.back();
    },
    handleScroll () {
      // 页面滑动的距离
      let scrollTop = document.documentElement.scrollTop;
      // console.log(scrollTop)
      // 当页面滑动的距离大于300px时元素显示，否则不显示
      if (scrollTop >= 200) {
        this.nav_show = false;
      } else {
        this.nav_show = false;
      }
    },
  },
  mounted () {
    window.addEventListener("scroll", this.handleScroll);
  },
  // 组件销毁前
  beforeDestroy () {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style  scoped>
.footers {
  width: 100%;
  display: flex;
  margin-top: 20px;
}
.footers > li {
  margin-right: 20px;
}
.container {
  background: #fff;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
}
.mengceng {
  background: rgba(0, 0, 0, 0.5);
  z-index: 2000;
  text-align: center;
  height: 100vh;
}
.right {
  width: 100%;
  height: 100vh;
  background: #fefefe;
  position: absolute;
  top: 0;
  right: 0;
}
.html {
  width: 80%;
  margin: 20px auto;
}
/* .textStyle :nth-child(2n) {
  text-indent: 2em;
} */
/* .textStyle p:not(:has(*)) {
  text-indent: 2em;
} */
.produce {
  width: 100%;
  min-height: 100vh;
  padding: 10px;
  box-sizing: border-box;
  background-image: url("https://dandaoipad.oss-cn-hangzhou.aliyuncs.com/dandaoipad/1872111773928.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 999;
  position: relative;
}
.produce_List {
  font-size: 16px;
  display: flex;
  justify-content: flex-start;
  font-weight: 550;

  margin: 20px 0 10px 40px;
}
.back {
  height: 30px;
  line-height: 30px;
  color: #000;
  border-radius: 10px;
  display: flex;
  align-items: center;
}
.produce_title {
  font-size: 20px;
  /* width: 80px; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  color: #333232;
  padding: 10px;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background: #fff;
}
.pName {
  color: #000;
  font-size: 18px;
  font-weight: 550;
  padding-bottom: 4px;
  box-sizing: border-box;
  border-bottom: 2.5px solid #59a8ee;
}
.pcreateTime {
  color: #d2d2d2;
  font-size: 14px;
}
.border {
  /* height: 2px;
  width: 100px;
  background: #59a8ee;
 
  margin-top:4px; */
}
.product_content {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
}
.product_content div {
  text-align: left;
  font-size: 14px;
  line-height: 24px;
  margin-top: 10px;
  /* border-bottom: 1px solid #cdcdcd; */
  padding-bottom: 10px;
}
.product_content div span {
  font-weight: 580;
}
.product_intro {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  padding-bottom: 20px;
  box-sizing: border-box;
}
.product_intro_img {
  width: 45%;
}
.product_intro_img img {
  width: 100%;
  height: 100%;
}
.product_intro_c {
  width: 45%;
  text-align: left;
  font-size: 16px;
}
.product_intro_b {
  width: 40%;
  margin-top: 50px;
  margin-left: 60%;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
}
.pagination {
  text-align: right;
  padding-right: 20px;
  box-sizing: border-box;
  margin-top: 20px;
}
.detail_bottom {
  width: 100%;
  padding: 0 30px 30px 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}

.detail_bottom_left {
  width: 40%;
}
.detail_bottom_right {
  width: 60%;
  display: flex;
  font-size: 16px;
  font-weight: 550;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px;
  box-sizing: border-box;
}
.detail_bottom_right2 {
  width: 100%;
  display: flex;
  font-size: 14px;
  padding: 30px;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.chakan {
  color: #fff;
  background: #409eff;
  border-radius: 6px;
  width: 100px;
  line-height: 30px;
  height: 30px;
  font-size: 14px;
}
/deep/ .closebtn .el-dialog {
  margin-top: 0 !important;
}
/deep/ .el-dialog__header {
  position: absolute;
  top: 0px;
  z-index: 99;
  width: 100%;
  height: 50px;
}
/deep/ .el-dialog__body {
  padding: 0;
  height: 100vh;
  width: 100%;
}
/deep/ .el-input__inner {
  background-color: rgba(0, 0, 0, 0) !important;
  border: 2px solid #01a0e9;
  border-radius: 8px;
  color: #01a0e9;
}
/deep/ .el-input.is-focus .el-input__inner {
  border-color: #01a0e9 !important;
}
.el-select-dropdown__item.selected {
  color: #01a0e9;
}
/deep/ .el-select .el-input__inner:focus {
  border-color: #01a0e9 !important;
}
/deep/ .el-dialog.is-fullscreen {
  height: auto !important;
  overflow: scroll !important;
}
/deep/ .el-icon-close:before {
  content: "\e6db";
  top: 5%;
  left: 96%;
  color: deepskyblue;
  position: fixed;
}

.report {
  width: 100%;
  display: flex;
  margin: 15px 0;
  align-items: flex-start;
  justify-content: flex-start;
}
.reportTitle {
  /* width:25%; */
  text-align: left;
}
.ntitle {
  width: 100%;
  margin-bottom: 5px;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #409eff;
  border-bottom: 1px solid #409eff;
}

.ntitleList {
  width: 60%;
  display: flex;
  flex-direction: column;
}
.more {
  width: 15%;
}
</style>